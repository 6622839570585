import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (!environment.production) {
	Sentry.init({
		dsn: "https://0c4eb5c5f380c3629566ee474334ff1a@o4506704968089600.ingest.sentry.io/4506704974381056",
		enabled: location.hostname !== 'test.workonweb.de',
		environment: 'staging',
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			/*Sentry.feedbackIntegration({
				colorScheme: "light",
				formTitle: "",
				buttonLabel: "",
				namePlaceholder: "",

			}),*/
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit {

	registerForm: FormGroup

	isInvited: boolean

	hideVisibilityOld: boolean = true;
	hideVisibilityNew: boolean = true;

	salutationOptions = [
		{ 'name': 'Keine Anrede', 'value': 'NONE' },
		{ 'name': 'Frau', 'value': 'MS' },
		{ 'name': 'Herr', 'value': 'MR' },
		{ 'name': 'Divers', 'value': 'DIVERSE' }
	]

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private dataService: DataService,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.isInvited = this.route.snapshot.queryParams['invited'] ? true : false;

		// this.registerForm = new FormGroup({});

		this.registerForm = this.formBuilder.group({
			username: new FormControl('', [Validators.required]),
			firstname: new FormControl(''),
			lastname: new FormControl(''),
			emailAddress: new FormControl('', [Validators.required, Validators.email]),
			salutation: new FormControl(),
			oldPassword: new FormControl('', [Validators.required]),
			newPassword: new FormControl('', [Validators.required]),
		})
		// If user is not invited, 'oldPasswort' and 'newPassword' fields are being used for password and password repeat
		// if ( !this.isInvited ) this.registerForm.get('newPassword').setValidators([Validators.required, MustMatch('oldPassword')])
	}

	onSubmit(): void {
		this.dataService.registerUser(this.isInvited, this.registerForm.value).subscribe((data: any) => {
			this.snackBar.open(
				'Sie haben sich erfolgreich registiert und können sich nun einloggen.', 
				null,
				{ duration: 5000, panelClass: ['success-snack'] }
			)
			this.router.navigate(['/login']);
		})
	}

}
